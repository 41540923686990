import state from "./moduleSiteState.js";
import mutations from "./moduleSiteMutations.js";
import actions from "./moduleSiteActions.js";
import getters from "./moduleSiteGetters.js";

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
