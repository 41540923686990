import axios from "@/axios.js";
export default {
  createCompte(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/account/create", { payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
