<template>
  <div
    class="w-full h-[300px] xs:h-auto bg-[#1B3367] flex flex-col justify-between px-10 py-5"
  >
    <div class="w-full flex xs:flex-col justify-between pb-5 border-b">
      <div class="flex flex-col text-left text-white">
        <h1 class="font-semibold">A propos de BEYIMA</h1>
        <ul class="font-light mt-2">
          <li class="hover:underline mt-2 cursor-pointer">
            Informations sur la société
          </li>
          <li class="hover:underline mt-2 cursor-pointer">Carrières</li>
          <li class="hover:underline mt-2 cursor-pointer">Evénements</li>
          <li class="hover:underline mt-2 cursor-pointer">
            Témoignages clients
          </li>
          <li class="hover:underline mt-2 cursor-pointer">Newsletters</li>
        </ul>
      </div>
      <div class="flex flex-col text-left text-white">
        <h1 class="font-semibold">Information sur le site</h1>
        <ul class="font-light mt-2">
          <li class="hover:underline mt-2 cursor-pointer">Confidentialité</li>
          <li class="hover:underline mt-2 cursor-pointer">
            Conditions d'utilisation
          </li>
          <li class="hover:underline mt-2 cursor-pointer">Evénements</li>
          <li class="hover:underline mt-2 cursor-pointer">Sitemap</li>
          <li class="hover:underline mt-2 cursor-pointer">
            Préférence relative aux cookies
          </li>
        </ul>
      </div>
      <div class="flex flex-col text-left text-white">
        <h1 class="font-semibold">Contactez-nous</h1>
        <ul class="font-light mt-2">
          <li class="flex items-center hover:underline mt-2 cursor-pointer">
            <img
              class="h-9 mr-1"
              src="@/assets/icons/adresse-b-50.png"
              alt=""
              srcset=""
            />
            <a href="#">Riviéra Cap Nord Abidjan, Côte d'ivoire</a>
          </li>
          <li class="flex items-center hover:underline mt-2 cursor-pointer">
            <img
              class="h-9 mr-1"
              src="@/assets/icons/phone-b-50.png"
              alt=""
              srcset=""
            />
            <a class="mr-1" href="tel:+2250777880925">+225-07-77-88-09-25 /</a>
            <a href="tel:+2250777219794">+225-07-77-21-97-94</a>
          </li>
          <li class="flex items-center hover:underline mt-2 cursor-pointer">
            <img
              class="h-9 mr-1"
              src="@/assets/icons/formulaire-b-50.png"
              alt=""
              srcset=""
            />
            <a href="#">Contactez-nous</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="w-full flex justify-between">
      <div></div>
      <div class="flex">
        <a href="#"
          ><img
            class="h-9 mr-1"
            src="@/assets/icons/facebook-b-50.png"
            alt=""
            srcset=""
        /></a>
        <a href="#"
          ><img
            class="h-9 mr-1"
            src="@/assets/icons/twitter-b-50.png"
            alt=""
            srcset=""
        /></a>
        <a href="#"
          ><img
            class="h-9 mr-1"
            src="@/assets/icons/instagram-b-50.png"
            alt=""
            srcset=""
        /></a>
        <a href="#"
          ><img
            class="h-9 mr-1"
            src="@/assets/icons/linkedin-b-50.png"
            alt=""
            srcset=""
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterPage",
};
</script>
