<template>
  <div class="home">
    <my-slider></my-slider>
    <!-- Notre expertise -->
    <div
      class="w-full h-auto bg-gradient-to-r from-[#00A9E6] to-[#1B3367] px-10 py-5"
    >
      <h1 class="text-5xl font-semibold">Notre expertises</h1>
      <div class="w-full grid grid-cols-3 xs:grid-cols-1 gap-2 mt-5">
        <div
          class="w-1/3x h-[200px] flex flex-col items-center justify-center border-[#1B3367] border-2 bg-white shadow-lg cursor-pointer px-3 py-4"
        >
          <div
            class="w-full flex justify-center text-[#1B3367] text-xl font-semibold hover:underline"
          >
            Développement de logiciels types ERP et CRM personnalisés pour
            répondre aux besoins spécifiques de nos clients
          </div>
        </div>
        <div
          class="w-1/3x h-[200px] flex flex-col items-center justify-center border-[#1B3367] border-2 bg-white shadow-lg cursor-pointer px-3 py-4"
        >
          <div
            class="w-full flex justify-center text-[#1B3367] text-xl font-semibold hover:underline"
          >
            La création de sites internet et d'applications web modernes et
            conviviales pour améliorer la présence en ligne et l'expérience
            utilisateur.
          </div>
        </div>
        <div
          class="w-1/3x h-[200px] flex flex-col items-center justify-center border-[#1B3367] border-2 bg-white shadow-lg cursor-pointer px-3 py-4"
        >
          <div
            class="w-full flex justify-center text-[#1B3367] text-xl font-semibold hover:underline"
          >
            Conception, mise en place et gestion de réseaux et de solutions de
            télécommunications performantes et sécurisées.
          </div>
        </div>
        <div
          class="w-1/3x h-[200px] flex flex-col items-center justify-center border-[#1B3367] border-2 bg-white shadow-lg cursor-pointer px-3 py-4"
        >
          <div
            class="w-full flex justify-center text-[#1B3367] text-xl font-semibold hover:underline"
          >
            Nous offrons des formations qualifiantes pour renforcer les
            compétences en technologies de nos clients et tout autre personne
            désirant gagner en expertise pour être compétitive sur le marché de
            l’emplois et booster.
          </div>
        </div>
        <div
          class="w-1/3x h-[200px] flex flex-col items-center justify-center border-[#1B3367] border-2 bg-white shadow-lg cursor-pointer px-3 py-4"
        >
          <div
            class="w-full flex justify-center text-[#1B3367] text-xl font-semibold hover:underline"
          >
            Nous proposons des solutions de sécurité informatique pour protéger
            les données sensibles de nos clients, prévenir les cyberattaques et
            garantir la conformité aux normes de sécurité.
          </div>
        </div>
        <div
          class="w-1/3x h-[200px] flex flex-col items-center justify-center border-[#1B3367] border-2 bg-white shadow-lg cursor-pointer px-3 py-4"
        >
          <div
            class="w-full flex justify-center text-[#1B3367] text-xl font-semibold hover:underline"
          >
            Fourniture d'équipements informatiques de haute qualité pour
            répondre aux besoins technologiques de nos clients.
          </div>
        </div>
      </div>
    </div>
    <!-- /Notre expertise -->
    <!-- Solution sur mesure -->
    <div class="w-full h-auto flex bg-gray-200 px-10 py-5">
      <div class="w-1/2 flex flex-col">
        <h1 class="text-5xl text-[#1B3367] font-semibold">
          Des solutions adaptées pour chaque entreprise
        </h1>
        <div class="text-lg text-black mt-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum,
          magni eos eaque culpa tenetur consequatur itaque! Impedit, eum
          dolores. Deserunt quas at doloremque consequuntur quae repellendus
          optio nihil odit corrupti.
        </div>
        <a
          href="#"
          class="w-[200px] bg-[#00A9E6] hover:bg-[#1B3367] hover:text-white px-4 py-3 mt-3"
          >Démarrer avec BEYIMA</a
        >
        <!-- <div class="w-[100px] h-1 bg-yellow-500 mt-2 transform rotate-0"></div> -->
      </div>
      <div class="w-1/2">
        <img src="@/assets/images/mockup-b.png" alt="" srcset="" />
      </div>
    </div>
    <!-- /Solution sur mesure -->
    <!-- Actualité -->
    <div class="w-full bg-white px-10 py-5">
      <div class="w-full flex justify-center">
        <h1 class="text-5xl font-semibold">Quoi de neuf !</h1>
      </div>
      <div class="grid grid-cols-2 xs:grid-cols-1 gap-2 mt-5">
        <div class="h-[400px] xs:h-auto shadow-lg xs:mt-2 cursor-pointer">
          <div class="card-header w-full h-2/5">
            <img
              class="object-cover h-full w-full"
              src="https://content.cdn.sap.com/is/image/sap/296501:2598x1648?wid=1280&hei=811&fit=stretch,1"
              alt=""
            />
          </div>
          <div class="card-body w-full h-3/5 xs:h-auto px-8 py-5">
            <div class="cat">Innovations IA</div>
            <h1 class="text-lg font-semibold text-[#00A9E6] mt-3">
              L'IA conçue pour votre entreprise
            </h1>
            <p class="mt-3">
              Nous avons une solution BEYIMA d'IA stratégique pour répondre à
              vos besoins opérationnels uniques. Apportez l'avenir - vous serez
              prêt à tout.
            </p>
            <button
              class="w-auto justify-center bg-[#1B3367] hover:bg-[#00A9E6] text-white px-4 py-3 mt-2"
            >
              Apprendre encore plus
            </button>
          </div>
        </div>
        <div class="h-[400px] xs:h-auto shadow-lg xs:mt-2 cursor-pointer">
          <div class="card-header w-full h-2/5">
            <img
              class="object-cover h-full w-full"
              src="https://content.cdn.sap.com/is/image/sap/280951:2598x1648?wid=1280&hei=811&fit=stretch,1"
              alt=""
            />
          </div>
          <div class="card-body w-full h-3/5 px-8 py-5">
            <div class="cat">Un rapport BEYIMA Afrique</div>
            <h1 class="text-lg font-semibold text-[#00A9E6] mt-3">
              La rareté des compétences technologiques en Afrique révélée
            </h1>
            <p class="mt-3">
              Découvrez comment le manque de compétences technologiques affecte
              les efforts de transformation numérique de l'Afrique, quelles
              compétences sont les plus demandées...
            </p>
            <button
              href="#"
              class="w-auto justify-center bg-[#1B3367] hover:bg-[#00A9E6] text-white px-4 py-3 mt-2"
            >
              Apprendre encore plus
            </button>
          </div>
        </div>
        <div class="h-[400px] xs:h-auto shadow-lg xs:mt-2 cursor-pointer">
          <div class="card-header w-full h-2/5">
            <img
              class="object-cover h-full w-full"
              src="https://www.sap.com/dam/application/imagelibrary/photos/278000/278132.jpg/_jcr_content/renditions/278132_homepage_3840_2508.jpg.adapt.1280_853.false.false.false.false.jpg/1559669500483.jpg"
              alt=""
            />
          </div>
          <div class="card-body w-full h-3/5 px-8 py-5">
            <div class="cat">Innovations en matière d'action climatique</div>
            <h1 class="text-lg font-semibold text-[#00A9E6] mt-3">
              Enregistrez, signalez et agissez sur vos données carbone
            </h1>
            <p class="mt-3">
              Agissez pour le climat avec une comptabilité carbone
              transactionnelle et atteignez le zéro net en évoluant vers un
              registre vert.
            </p>
            <button
              href="#"
              class="w-auto justify-center bg-[#1B3367] hover:bg-[#00A9E6] text-white px-4 py-3 mt-2"
            >
              Apprendre encore plus
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Actualité -->
    <!-- Newsletter -->
    <div class="w-full h-[200px] bg-[#00A9E6] flex xs:flex-col px-10 py-5">
      <div class="flex flex-col justify-center mr-10">
        <h1 class="text-5xl font-semibold">Newsletter</h1>
        <h3 class="text-xl">Restez à l'écoute de l'actualité BEYIMA</h3>
      </div>
      <div class="w-[70%] xs:w-full flex flex-col justify-center xs:mt-2">
        <div class="w-full flex items-center">
          <input
            @input="isEmailValid()"
            v-model="email"
            placeholder="Entrez votre adresse e-mail"
            class="w-[50%] xs:w-[80%] placeholder:italic placeholder:text-black px-4 py-3 mr-2"
            type="email"
          />
          <a
            @click="subscribeNewsLetter()"
            class="w-auto flex justify-center bg-[#1B3367] hover:bg-[#00A9E6] hover:border hover:border-[#1B3367] text-white px-4 py-3 cursor-pointer"
            >Inscription</a
          >
        </div>
        <div v-if="attempt && missingEmail" class="text-red-600">
          <span>Veuillez saisir une adresse e-mail.</span>
        </div>
        <div v-if="attempt && wrongEmail" class="text-red-600">
          <span>Adresse e-mail invalide.</span>
        </div>
      </div>
    </div>
    <!-- /Newsletter -->
  </div>
</template>

<script>
// @ is an alias to /src
import MySlider from "@/components/MySlider.vue";
export default {
  name: "HomeView",
  components: {
    MySlider,
  },
  data() {
    return {
      email: null,
      attempt: false,
      wrongEmail: false,
    };
  },
  computed: {
    missingEmail() {
      return (
        this.email === null || this.email === undefined || this.email === ""
      );
    },
  },
  methods: {
    isEmailValid() {
      this.attempt = true;
      const emailRe = new RegExp(
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (emailRe.test(this.email)) {
        this.wrongEmail = false;
        this.attempt = false;
      } else {
        this.wrongEmail = true;
        console.log(this.wrongEmail);
      }
    },
    subscribeNewsLetter() {
      this.attempt = true;
      if (this.missingEmail) {
        event.preventDefault();
      } else {
        const payload = {
          email: this.email,
        };
        this.$store
          .dispatch("site/subscribeNewsLetter", payload)
          .then(() => {})
          .catch((err) => {
            console.log(err);
            // this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
