import { createStore } from "vuex";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import moduleCompte from "./compte/moduleCompte";
import moduleSite from "./site/moduleSite";

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    compte: moduleCompte,
    site: moduleSite,
  },
});
