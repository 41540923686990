import axios from "axios";

//<<<<<<< M.Djezou
//const baseURL = "http://localhost:3000";
const baseURL = "https://api.fovea-erp.com";
//const baseURL = 'http://185.98.128.180:3000'
//=======
//const baseURL = 'http://127.0.0.1:8124'
//>>>>>>> main
export default axios.create({
  baseURL,
  // You can add your headers here
});
