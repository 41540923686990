<template>
  <HeaderPage />
  <router-view />
  <FooterPage />
</template>
<script>
// @ is an alias to /src
import HeaderPage from "@/components/HeaderPage.vue";
import FooterPage from "@/components/FooterPage.vue";

export default {
  name: "HomeView",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style></style>
